import React from 'react';
import "swiper/css/autoplay";

// components
import dynamic from 'next/dynamic';
// Swiper
import "swiper/css";
import "swiper/css/navigation";
import Schema from '../../helper/schemaHelper';

const TopSellers = dynamic(() => import('./TopSellers'));
const HeaderSection = dynamic(() => import('./HeaderSection'))

const TopCollection = dynamic(() => import('./TopCollection'))
const DiscoverSection = dynamic(() => import('../home/DiscoverSection'))
const LiveAuctions = dynamic(() => import('../home/LiveAuctions'))
const BrowseCategory = dynamic(() => import('./BrowseCategory'))
const CreateNFT = dynamic(() => import('./CreateNFT'))
const SellNFT = dynamic(() => import('./SellNFT'))
const Resource = dynamic(() => import('./Resources'))
const IndexVersionTwo = (props) => {

    const { orgSchema, websiteSchema } = Schema;

    const {
        meta, currLocale, categoryList, faq, resources, currentTheme,
        auctionList, sellerList, hotCollections, allNftList
    } = props;
    return (
      <>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: orgSchema(meta) }} />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: websiteSchema }} />

        <HeaderSection content={meta}
                       currentTheme={currentTheme}
                       currLocale={currLocale} />

        {/* <HotCollection version={this.props.version} /> */}
        <DiscoverSection title={true}
                         currLocale={currLocale}
                         categoryList={categoryList}
                         allNftList={allNftList} />
        {/* <PopularSeller  currLocale={this.props.currLocale} /> */}
        <TopSellers currLocale={currLocale}
                    sellerList={sellerList} />
        {/* <Seller currLocale={this.props.currLocale} /> */}
        <LiveAuctions title={true}
                      currLocale={currLocale}
                      auctionList={auctionList} />
        <BrowseCategory currLocale={currLocale}
                        categoryList={categoryList} />
        <TopCollection hotCollections={hotCollections} />
        <CreateNFT />
        <SellNFT faqData={faq} />
        <Resource resources={resources} currLocale={currLocale} />
        {/* <AppBanner /> */}
      </>
    )

}
export default IndexVersionTwo;